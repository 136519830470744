import {createOidcAuth, LogLevel, SignInType} from 'vue-oidc-client/vue3'

let mainOidc = null

if (window.auth) {

    if (process.env.VUE_APP_SSO_URL != undefined) {
        window.auth.authority = process.env.VUE_APP_SSO_URL;
    }
    if (process.env.VUE_APP_SSO_CLIENT_ID != undefined) {
        window.auth.client_id = process.env.VUE_APP_SSO_CLIENT_ID;
        window.auth.scope = `openid profile email api ${process.env.VUE_APP_SSO_CLIENT_ID}`;
    }

    const loco = window.location
    const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;
    mainOidc = createOidcAuth(
        'main',
        SignInType.Window,
        appRootUrl,
        window.auth,
        console,
        LogLevel.Error
    )
    // eslint-disable-next-line no-unused-vars
    mainOidc.events.addUserLoaded(function(user) {
    })
}

export default mainOidc
