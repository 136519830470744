import { createApp, watch } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import {mixin, elementPlusLocale} from '@f1/upc-webapp-ui-vue3'
import elementplus from 'element-plus'
import {i18n} from './i18n.js'
import mainAuth from './auth'
import axios from './http-common'

const init = (mainAuth) => {
    const app = createApp(App)
    if (mainAuth != null) {
        app.config.globalProperties.$oidc = mainAuth
    }

    // Afegir token autentificació
    axios.interceptors.request.use(function (config) {
        if(mainAuth.isAuthenticated){
            if (!config.url.startsWith("http")) {
                config.headers.Authorization =  "Bearer " + mainAuth.user.id_token;
            }
        }
        return config;
    });

    // load user
    store.dispatch('loadUser', mainAuth.userProfile)

    // Per actualitzar al moment
    watch(mainAuth, (oidc) => {
        if (oidc?.isAuthenticated) {
            store.dispatch('loadUser', mainAuth.userProfile)
        }
    })


    app.use(elementplus, {locale: elementPlusLocale})
    app.use(i18n)
    app.mixin({...mixin})

    app.use(router)
    app.use(store)
    app.mount('#app')
    return app
}

if (window.auth) {
    mainAuth.useRouter(router)
    mainAuth.startup().then(success => {
        if (success) {
            init(mainAuth)
        } else {
            console.log('Startup was not ok')
        }
    })
} else {
    init(null)
}
