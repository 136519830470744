<template>
    <Main
        :itemsMenuNavegacio="itemsMenuNavegacio"
        :titolApp="titolApp"
        :itemsBarraPrincipal="[myPerfil]"
        linkInformacio="https://gitlab.upc.edu/f1/upc-webapp-ui-vue3-exemple/-/blob/master/README.md"
        :show-watermark="true"
        :show-alert="true"
        :tagversion="version"
    ></Main>
</template>

<script>

import 'element-plus/theme-chalk/index.css'
import {Main, Perfil} from '@f1/upc-webapp-ui-vue3'
import {mapGetters} from "vuex"
import {markRaw} from "vue"

export default {
    name: 'App',
    components: {
        Main
    },
    data() {
        return {
            myPerfil: {
                component: markRaw(Perfil),
                props: {
                    nom: this.$oidc.isAuthenticated ? console.log(this.$oidc.userProfile) : "Login",
                    email: "",
                }
            }
        }
    },
    computed: {
        ...mapGetters([
          'hasRole',
        ]),

        version() {
            const { version } = require('../package.json')
            return version
        },
        titolApp() {
            return this.$t("BIBAdmin")
        },
        itemsMenuNavegacio() {
            return [
                {
                    to: '/',
                    icon: 'home',
                    text: this.$t('Inici'),
                    visible: this.hasRole("ROLE_LLIBRES_IDP_USER")
                    //visible: this.$store.state.user.roles.includes("ROLE_LLIBRES_IDP_USER")
                },
                {
                    tos: [
                        {to: '/pagina-publica', text: this.$t('Pàgina pública')},
                        {to: '/pagina-privada', text: this.$t('Pàgina privada')},
                        {to: '/pagina-inexistent', text: this.$t('Pàgina inexistent')},
                    ],
                    icon: 'format-list-bulleted',
                    text: this.$t('Pàgines')
                },
                {
                    to: '/crud',
                    tos: [
                        {to: '/crud/default', text: this.$t('Default')},
                        {to: '/crud/filter-and-default-sort', text: this.$t('Filter and default sort')},
                        {to: '/crud/pagination-and-slot', text: this.$t('Pagination and slot')},
                        {to: '/crud/full', text: this.$t('Full')},
                        {to: '/crud/crudserver', text: this.$t('CRUDServer')},
                    ],
                    icon: 'table',
                    text: this.$t('CRUD')
                },
            ]
        }
    },
    created() {
        this.showLoading(this.$t('Loading sample'), 1000)
    }
}

</script>
