export const name = "public-addon"

export const location = "leftMenu"

export const isPublic = true

export const route = { path: `/${name}`, name, component: () => import(/* webpackChunkName: "addon" */ './Component.vue') }

// eslint-disable-next-line no-unused-vars
export const getMenuItem = (vue) => {
    return {
        to: `/${name}`,
        icon: 'folder-lock-open',
        text: vue.$t("Public addon"),
        visible: true,
    }
}
