import { createStore } from 'vuex'

import { store as template } from '@f1/upc-webapp-ui-vue3'
import http from "@/http-common";

const store = createStore({
    state: {
        user: {},
        userLoading: false,
        logged: false
    },

    getters: {
        user(state) { return state.user },
        isLogged: state => { return state.logged },

        hasRole: state => (roles) => {
            if(state.user.roles == null) return false
            return state.user.roles.includes(roles)
        },


    },

    mutations: {
        setUser (state, user) { state.user = user },
        setLogged(state, status) { state.logged = status },
    },

    actions: {
        async loadUser({commit, state}, userProfile) {
            if (state.userLoading) return

            try {
                state.userLoading = true

                let response = await http.get('/user/info/' + userProfile.sub)
                let userInfo = await response.data

                commit('setUser', userInfo)

            } catch(error) {
                // eslint-disable-next-line no-console
                console.warn(error)
            } finally {
                state.userLoading = false
            }
        },


    },

    modules: {
        template
    },
})
export default store
