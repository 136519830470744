import {Error, initRouter} from '@f1/upc-webapp-ui-vue3'
import mainAuth from './auth'

const publicRoutes = [
    { path: '/crud/default', name: 'crud-default', component: () => import(/* webpackChunkName: "pagina" */ './components/CRUD/Default.vue') },
    { path: '/crud/filter-and-default-sort', name: 'filter-and-default-sort', component: () => import(/* webpackChunkName: "pagina" */ './components/CRUD/FilterAndDefaultSort.vue') },
    { path: '/crud/pagination-and-slot', name: 'pagination-and-slot', component: () => import(/* webpackChunkName: "pagina" */ './components/CRUD/PaginationAndSlot.vue') },
    { path: '/crud/full', name: 'full', component: () => import(/* webpackChunkName: "pagina" */ './components/CRUD/Full.vue') },
    { path: '/crud/crudserver', name: 'crudserver', component: () => import(/* webpackChunkName: "pagina" */ './components/CRUD/CRUDServerPassengers.vue') },

    { path: '/error-ssl/:page', name: 'error-ssl', component: () => import(/* webpackChunkName: "error" */ './components/ErrorSsl.vue'), props: true},
    { path: '/pagina-publica', name: 'pagina-publica', component: () => import(/* webpackChunkName: "pagina" */ './components/Pagines/PaginaPublica.vue') },
    { path: '/:pathMatch(.*)*', name: 'error', component: Error },
]

const privateRoutes = [

    { path: '/', name: 'home', component: () => import(/* webpackChunkName: "home" */ './components/Home/Home.vue'), alias: '/home' },
    { path: '/pagina-privada', name: 'pagina-privada', component: () => import(/* webpackChunkName: "pagina" */ './components/Pagines/PaginaPrivada.vue') },
]

const router =  initRouter(mainAuth, publicRoutes, privateRoutes)

export default router
